import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export interface ResDestinationType {
  data: Desti[];
}
export interface Desti {
  id: string;
  type: string;
  attributes: {
    id: number;
    title: string;
    name_of_destination: string;
    created_at: string;
    image: string;
  };
}
export interface SingleOfferType {
  data: {
    id: string;
    type: string;
    attributes: {
      id: number;
      title: string;
      tagline1: string;
      tagline2: string;
      offer_code: string;
      offer_type: string;
      travel_city: string;
      travel_date: string | null;
      expiry_date: string | null;
      created_at: string;

      discount_amount: string | null;
      description: string | null;
      image: string;
      offer_code_image: string;
    };
  };
  errors: {
    massage: string;
  };
}
export interface PromocodeAttributes {
  discount_type: "fixed" | "percentage";
  discount: number;
  min_order_amount: number;
  name: string;
  to: string;
  description?: string;
}
export interface Promocode {
  attributes: PromocodeAttributes;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  promocodes: Array<string>;
  loading: boolean;
  cityId: string;
  token: string;
  promocode?: Promocode;
  singleOfferData: SingleOfferType;
  destinationData: Desti[];
  toastOpen:boolean;
  CouponMsg:string;
  showNoOfferUi: boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PromocodesController extends BlockComponent<Props, S, SS> {
  apiPromoCodeCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      CouponMsg:"",
      promocodes: [],
      toastOpen:false,
      loading: true,
      cityId: "",
      token: "",
      showNoOfferUi: false,
      promocode: undefined,
      singleOfferData: {
        data: {
          id: "",
          type: "",
          attributes: {
            id: 0,
            title: "",
            tagline1: "",
            tagline2: "",
            offer_code: "",
            offer_type: "",
            travel_city: "",
            travel_date: null,
            expiry_date: null,
            created_at: "",
            discount_amount: null,
            description: null,
            image: "",
            offer_code_image: "",
          },
        },
        errors: {
          massage: "",
        },
      },
      destinationData: [],
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.getDestination();

    this.getSingleOffers();

    // Customizable Area End
  }

  getToken = () => {
    const msgConst: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msgConst);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
      this.getPromocodesData(token, this.state.cityId);
    }

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.apiPromoCodeCallId) {
        if (responseJson?.data) {
          this.setState({ promocodes: responseJson.data, loading: false });
        } else {
          this.setState({ loading: false });
        }
      }
      if (apiRequestCallId === this.getSingleOffersApiCallId) {
        this.OffersRes(responseJson);
      }
      if (apiRequestCallId === this.getDestinationApiCallId) {
        this.singleDestinationRes(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  getSingleOffersApiCallId: string = "";
  getDestinationApiCallId: string = "";
  async getPromocodesData(token: string, cityId: string) {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPromoCodeCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPromocodesEndPoint + cityId || 3
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getPromocodesAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleRedirection(promocode: string) {
    this.props.navigation.navigate("PromocodeDetails", {
      promocode: JSON.stringify(promocode),
    });
  }
  getSingleOffers = () => {
    const PCheaders = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getOffersMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSingleOffersApiCallId = getOffersMsg.messageId;

    getOffersMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(PCheaders)
    );
    getOffersMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getPromocodesAPIMethod
    );
    getOffersMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.OffersSingleEndPoint + this.props.navigation.getParam("id")
    );
    runEngine.sendMessage(getOffersMsg.id, getOffersMsg);
    return true;
  };
  removeHtmlTags = (html: string) => {
    const element = document.createElement("div");
    element.innerHTML = html;
    return element.textContent || element.innerText || "";
  };
  OffersRes = (responseJson: SingleOfferType) => {
    if (!responseJson.errors && responseJson) {
      this.setState({showNoOfferUi:false, singleOfferData: responseJson });
    }else{
      this.setState({showNoOfferUi: true})
    }
  };

  getDestination = () => {
    const PCheaders = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const getDestinationMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDestinationApiCallId = getDestinationMsg.messageId;

    getDestinationMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.trendingDestinationEndPoint
    );

    getDestinationMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(PCheaders)
    );
    getDestinationMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getPromocodesAPIMethod
    );
    runEngine.sendMessage(getDestinationMsg.id, getDestinationMsg);
    return true;
  };
  singleDestinationRes = (responseJson: ResDestinationType) => {
    if (responseJson.data.length > 0) {
      this.setState({ destinationData: responseJson.data });
    }
  };
  clickCopyFun=(value:any, sValue:any)=>{
    this.setState({toastOpen: value,CouponMsg:sValue})
  }
  handleHome = ()=>{
    this.props.navigation.navigate('Home')
  }
  offerTypeSubmit=(iata_object:any)=>{
    if(iata_object!=null){
    if(this.state.singleOfferData.data.attributes.offer_type==="package"){
          const packageMessage: Message = new Message(
          getName(MessageEnum.itaCodeDataMessage)
        );
        packageMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        packageMessage.addData(getName(MessageEnum.itaCodeDataInfo),{iata_object: iata_object,});
        this.send(packageMessage);

    }else{
      const offerMessage: Message = new Message(
        getName(MessageEnum.NavigationHomeScreenMessage)
      );
      offerMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      offerMessage.addData(getName(MessageEnum.itaCodeOfferInfo),{iata_object: iata_object,});
      this.send(offerMessage);
    }
  }
}
  // Customizable Area End
}
