import React from "react";
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css'; 
import "../../blocks/landingpage/assets/style.css"

const customLocale = {
    format: 'yyyy-MM-dd',
    separator: ' - ',
   
    toLabel: 'To',
    applyLabel: 'Apply',
    cancelLabel: 'Cancel',
    fromLabel: 'From',
    customRangeLabel: 'Custom',
    daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    monthNames: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    firstDay: 0, 
    localize: {
      month: (monthIndex:any) => customLocale.monthNames[monthIndex],
      day: (dayIndex:any) => customLocale.daysOfWeek[dayIndex], // Add the day function
      weekStart: () => customLocale.daysOfWeek[customLocale.firstDay],
      weekNumber: () => 'W',
      today: () => 'Today',
    },
    formatLong: {},
  };
const SingleCalender = (props:any) =>  {
        const {state,
            handleDateChange,month
        } = props
        
        return (
<DateRangePicker
  ranges= {[
    {
      startDate: state.startDate || new Date(),
        endDate: state.endDate || new Date(),
        key: 'selection',
    },
  ]}
  minDate={new Date()}
  showSelectionPreview={true}
  moveRangeOnFirstSelection={false}
  focusedRange={[0 , month===1?0:1]}

  dayContentRenderer={(date:Date)=>{
   return  <React.Fragment>
   <span style={{fontSize:"12px"}}>
     {
       date.getDate()
     }
   </span>
  

 </React.Fragment>
  }}
  onChange={handleDateChange}
  months={month===1?1:2}
  direction="horizontal"
  //@ts-ignore
  locale={customLocale}
/>
        
            )
}
export default SingleCalender;

