import React from "react";
// Customizable Area Start
import { Box, Typography, Button, Drawer, Grid, MenuList, MenuItem, TextField, Menu, CircularProgress, InputAdornment, Popover } from "@material-ui/core";
import { errorIcon,flightIcon} from "./assets";
import Loader from "../../../components/src/Loader.web";
import { ArrowForwardIos, ArrowBackIos, Tune } from "@material-ui/icons";
import { createTheme } from "@material-ui/core/styles";
import HeaderMenuBlock from "../../../blocks/navigationmenu/src/HeaderMenu.web";
import '../assets/style.css'
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import Filteritems from "../../filteritems/src/Filteritems.web";
import Calender from "../../../components/src/Calender";
import SingleCalender from "../../../components/src/SingleCalender";
import NotFound from "../../../components/src/NotFound";
import PriceModal from "../../../components/src/PriceModal";
import FlightIcon from '@material-ui/icons/Flight';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import SearchIcon from '@material-ui/icons/Search';
interface SearchTextObj{
  id:string,
  state:string,
  country:string,
  flight:string,
  stateCode:string
}
interface FlightData {
  id: number;
  AirlineName: string;
  flight_number: string;
  origin: string;
  destination: string;
  connection: number;
  departure_time: string;
  arrival_time: string;
  endArrival_time: string;
  per_adult_price: string;
  duration: string;
}

interface OfferButtonList {
  src: string;
  heading: string;
  content: string;
}

interface DatePriceList {
  dateItem: string;
  isHighlighted: boolean;
}

interface LayourInfo{
      change_flight: boolean;
      origin: string;
      destination: string;
      departure_time: string;
      arrival_time: string;
      layover_time: string;
      duration: string;
      FlightNumber: string;
      AirlineName:string;
}
// Customizable Area End

import CatalogueController, { Props } from "./CatalogueController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class Catalogue extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  formInputText=()=>{
    return(
      <>
        {this.state.searchText.state && this.state.searchText.flight ? (
             <div style={{position: "absolute",
             width:"94%",
              background: "rgb(19, 81, 130)", padding: "5px 0"}}>
                <span className="textcalenderday" style={{fontWeight:"700", fontSize: "32px", color:"#fff" }}>
                  {this.state.searchText.state}</span>
                <span className="textcalendername" style={{fontWeight:"500", fontSize: "16px",
                 display:"block", marginTop:"10px",color:"#fff", width:"96%" }}>{this.state.searchText.stateCode},{this.state.searchText.flight}</span>
             </div>
          ) : ''}
      </>
    )
  }
  nationalInputText = () => {
    return (
      <>
        {this.state.selectedNationality ? (
          <div style={{
            position: "absolute",background: "rgb(19, 81, 130)", width: "92%",color:"white",
            left: "0px", top: "26px",
            padding: "15px 0"
          }}>
            <span className="calenderMonth" style={{ fontWeight: "500", fontSize: "24px" }} data-test-id="formatMonth">{this.state.selectedNationality}</span><br />
          </div>
        ) : ''}
      </>
    )
  }
  toInputText=()=>{
    return(
      <>
       {this.state.toText.state && this.state.toText.flight ? (
                                        <div style={{position: "absolute",width:"92%",
                                          background: "rgb(19, 81, 130)",
                                          left:"1px",
                                          padding: "5px 0"}}>

                                          <span className="textcalenderday" style={{fontWeight:"700", fontSize: "32px",
                                          color:"#fff" }}>{this.state.toText.state}</span>
                                          <span className="textcalendername" style={{fontWeight:"500", fontSize: "16px", display:"block", marginTop:"10px",color:"#fff", }}>{this.state.toText.stateCode},{this.state.toText.flight}</span>
                                        </div>
                                      ) : ''}
      </>
    )
  }
  getFlightsList(item: FlightData, index: number) {
    return (<>
      <Box sx={flightItemStyle.container} key={index} className="flight-card" data-test-id="flight-result">
        <Box sx={flightItemStyle.topRow} className="flight-card-topRow">
          <Box sx={flightItemStyle.column}>
            <Typography variant="body1" className="flight-card-heading" style={flightItemStyle.flightHeadingC}>{item.origin}</Typography>
            <Typography variant="body2" className="flight-card-heading" style={flightItemStyle.placeTextC}>{item.origin}</Typography>
            <Typography variant="body2" className="flight-card-des">{this.convertFormatTime(item.departure_time)} 
            </Typography>
          </Box>
          <Box sx={flightItemStyle.durationColumn}>
            <Typography variant="body1" style={flightItemStyle.flightHeadingC} className="flight-card-heading">{this.DateComponent(item.departure_time)}</Typography>
            <img style={{ width: '105px', height: '24px', objectFit: 'cover' }} className="flight-card-icon" src={flightIcon} />
            <Box sx={flightItemStyle.flightDetails}>
              <Typography variant="body2" style={{ marginRight: '5px' }} className="flight-card-des">{item.duration} ({item.connection != 0 ? `${item.connection} Stop` : "Non Stop"})</Typography>
            </Box>
          </Box>

          <Box sx={flightItemStyle.column}>
            <Typography variant="body1" style={flightItemStyle.flightHeadingC} className="flight-card-heading">{item.destination}</Typography>
            <Typography variant="body2" style={flightItemStyle.placeTextC} className="flight-card-heading">{item.destination} </Typography>
            <Typography variant="body2" className="flight-card-des">{this.convertFormatTime(item.endArrival_time)}</Typography>
          </Box>
          <Box className="flight-card-priceContainer" id="flight-card-priceCont">
            <Typography variant="body1" style={flightItemStyle.flightHeadingC} className="flight-card-heading">INR {item.per_adult_price}</Typography>
            <Typography variant="body2" style={flightItemStyle.placeTextC} className="flight-card-heading">Per Adult</Typography>
          </Box>

          <Button data-test-id={`view-price${index}`} onClick={() => this.handleShowPriceModal(item, index)} className="view-prices" variant="contained" style={{ color: 'white', background: '#1764A0', padding: '10px, 12px, 10px, 12px', textTransform: 'none', borderRadius: '8px', fontFamily: 'Inter, sans-serif', fontWeight: '600', fontSize: '14px' }}>View Prices</Button>
        </Box>
        <Box sx={flightItemStyle.bottomRow} className="flight-card-content-Parent">
          <Box sx={flightItemStyle.flightDetails}>
          <FlightIcon style={{color:"#1764A0"}} />

            <Typography style={{fontSize: '16px', fontFamily: "Inter, sans-serif",
            fontWeight: '600',}} className="flight-card-content" >{item.AirlineName}</Typography>
          </Box>
          <Box>
          <Typography variant="body2" className="flight-card-content travel-class-name " style={{
            fontFamily: "Inter, sans-serif",
            fontWeight: '600',
            fontSize: '16px',
          }}>{"Flight No"} - {item.flight_number}</Typography>
          </Box>
          <Box className="flight-price-container-mobile" id="">
            <Typography variant="body1" style={flightItemStyle.flightHeadingC} className="flight-card-heading">INR {item.per_adult_price}</Typography>
            <Typography variant="body2" style={flightItemStyle.placeTextC} className="flight-card-heading">Per Adult</Typography>
          </Box>
           <Box>
            <Button style={{padding:"0px"}} data-test-id={`handleShowDetail${index}`} onClick={() => this.handleShowDetail(item.id)}>
                  <Typography
                    variant="body2"
                    className="flight-card-content travel-class-name"
                    style={{
                      fontFamily: 'Inter, sans-serif',
                      fontWeight:600,
                      fontSize: '16px',
                      textTransform:"capitalize",
                      display:"flex",
                      alignItems:"center",
                      color:"rgba(23, 100, 160, 1)"
                    }}
                  >
                    {"Flight Details"} {this.state.visibleDetailIndex!= item.id ?<ExpandMoreIcon />:<ExpandLessIcon />}
                  </Typography>
              </Button>
          </Box>
          <Button data-test-id={`view-price-mobile${index}`} onClick={() => this.handleShowPriceModal(item, index)} className="view-price-mobile" variant="contained" style={{ color: 'white', background: '#1764A0', padding: '10px, 12px, 10px, 12px', textTransform: 'none', borderRadius: '8px', fontFamily: 'Inter, sans-serif', fontWeight: '600', fontSize: '10px' }} >View Prices</Button>
        </Box>
        
      </Box>
      {this.state.visibleDetailIndex === item.id && (
            <Box sx={flightItemStyle.container} key={index} className="flight-card">
            <Box sx={flightItemStyle.topRow1} className="flight-card-topRow">
            <Box style={{width:"100%"}}>
      
 <Box style={{width:"100%"}}>
 <Typography style={{fontWeight:700, fontSize:"16px"}} variant="body1">FLIGHT DETAILS </Typography> 

            <>
             <Box style={{background:"rgba(23, 100, 160, 1)", color:"#fff", padding:"10px", margin:"10px 0"}}>
   <Typography variant="subtitle1">  {this.state.filterFlightDetails?.route}</Typography>
 </Box>
{this.state.filterFlightDetails?.layour_info.map((itemEle:LayourInfo)=>(
  <Box sx={{ width:{xs:"100%", sm:"100%", md:"80%"}}}>
 <Grid container spacing={2}>
  <Grid item xs={12} sm={12} md={4}>
  <Box sx={{...flightItemStyle.flightDetails, marginTop:{xs:"0", sm:"0",md:"0", lg:"15px"}}}>
            <img style={{
              objectFit: 'cover', width: '24px',
              height: '24px',
              marginRight: '8px',
              borderRadius: '50%',
            }} src="https://i.pinimg.com/1200x/2d/06/2c/2d062c935dde7754fa80bf011a9dbdc7.jpg" alt="flight logo" />

            <Typography style={{...webStyle.textFlightd, fontWeight:700}} variant="body1">{itemEle.AirlineName} <br/> {itemEle.FlightNumber}</Typography>
          </Box>
  </Grid>
  <Grid item xs={12} sm={12} md={8}>
    <Box style={flightItemStyle.bottomRow1}>
  <Box style={{textAlign:"left"}}>
 <Typography  style={{...webStyle.textFlightd, fontWeight:700}} variant="subtitle1" >
 {this.convertFormatTime(itemEle.departure_time)}
 </Typography>
 <Typography  style={{...webStyle.textFlightd, color:"rgba(100, 116, 139, 1)", fontWeight:700}} variant="subtitle1" >
 {this.DateComponent(itemEle.departure_time)}
 </Typography>
 <Typography  style={webStyle.textFlightd} variant="subtitle1">
 {itemEle.origin}
 </Typography>
</Box>
<Box style={{textAlign:"center"}}>
 <Typography  style={{...webStyle.textFlightd, fontWeight:700, borderBottom:"2px solid rgba(100, 116, 139, 1)"}} variant="subtitle1">
 {itemEle.duration}
 </Typography>
 <Typography  variant="subtitle1" style={{...webStyle.textFlightd,color:"rgba(23, 100, 160, 1)"}}>
 {itemEle.layover_time} {this.getLayoverMessage(itemEle, item.connection)}
 </Typography>
</Box>
<Box style={{paddingLeft:'5px'}}>
 <Typography  style={{...webStyle.textFlightd, fontWeight:700}} variant="subtitle1">
 {this.convertFormatTime(itemEle.arrival_time)}
 </Typography>
 <Typography  style={{...webStyle.textFlightd, color:"rgba(100, 116, 139, 1)", fontWeight:700}} variant="subtitle1" >
 {this.DateComponent(itemEle.arrival_time)}
 </Typography>
 <Typography  style={webStyle.textFlightd} variant="subtitle1">
 {itemEle.destination}
 </Typography>
</Box>
</Box>
    </Grid>
 </Grid>

 </Box>
))}
            </>

</Box>
                </Box>
            </Box>
            </Box>
            )}
      
      </>


    );
  }
  allButtonList = () => {
    return (
      <Box style={{ padding: "12px" }}>
        <Grid container spacing={1}>
          <Grid xs={12}>
            <div className="buttonContainer">
              <button
                className={`buttonEconomy ${this.state.travelStatus === 'PremiumEconomy' ? 'active' : ''}`}
                data-test-id="handleClickStatus"
                onClick={() => this.handleClickStatus('PremiumEconomy')}
              >
                Premium /
                Economy
              </button>
              <button
                className={`buttonEconomy ${this.state.travelStatus === 'PremiumFirst' ? 'active' : ''}`}
                data-test-id="handleClickStatus2"
                onClick={() => this.handleClickStatus('PremiumFirst')}
              >
                Premium /
                First
              </button>
              <button
                className={`buttonEconomy ${this.state.travelStatus === 'First' ? 'active' : ''}`}
                data-test-id="handleClickStatus3"
                onClick={() => this.handleClickStatus('First')}
              >
                First
              </button>
              <button
                className={`buttonEconomy ${this.state.travelStatus === 'Business' ? 'active' : ''}`}
                data-test-id="handleClickStatus4"
                onClick={() => this.handleClickStatus('Business')}
              >
                Business
              </button>
              <button
                className={`buttonEconomy ${this.state.travelStatus === 'Economy' ? 'active' : ''}`}
                data-test-id="handleClickStatus5"
                onClick={() => this.handleClickStatus('Economy')}
              >
                Economy
              </button>

            </div>
          </Grid>
        </Grid>
      </Box>
    )
  }

  getDatesList = (item: DatePriceList, index: number) => {
    return (
      <div id={`date-item-${index}`} data-test-id={`date-price${index}`} key={index} style={{

        borderBottom: item.isHighlighted ? '5px solid #1764A0' : 'none',
        textAlign: 'center',
        border: '0px, 0px, 4px, 0px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        background: 'white',
        cursor: 'pointer',
        minWidth: '133px',
        height: '100%',
       
        boxShadow: "0px 4px 4px 0px #00000029"
      }} onClick={() => this.handleDateSelect(item)} >
        <Typography style={{ color: item.isHighlighted ? '#1764A0' : 'black', fontWeight: item.isHighlighted ? 'bold' : 'normal' }}>
          {this.formatDateFilter(item.dateItem)}
        </Typography>
      </div>
    );
  }


  getOffersButton = ({ src, heading, content }: OfferButtonList) => {
    return (
      <Box key={heading} className="offer-card" style={{
        width: '208px',
        height: '56px',
        border: '2px solid #1764A0',
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
        justifyContent: 'center',
        flexGrow: '1'
      }}
      >
        <img className="offer-card-img" style={{ width: '33px', height: '32px' }} src={src} alt={heading} />
        <div>
          <Typography style={{ fontSize: '12px', fontWeight: '600', fontFamily: 'Inter, sans-serif' }} className="offer-card-heading">{heading}</Typography>
          <Typography style={{ fontSize: '10px', fontWeight: '500', fontFamily: 'Inter, sans-serif', color: '#64748B' }} className="offer-card-content">{content}</Typography>
        </div>
      </Box>
    );
  };

  travelInputText = () => {
    return (
      <>
        {this.state.fullTravelObj.countAdult &&
          this.state.fullTravelObj.travelStatus ? (
          <div style={{
            position: "absolute", width: "150%",
           
            padding: "5px 0", background: "rgb(19, 81, 130)", left: "0px", top: "25px"
          }}>
            <span className="calenderDay" data-test-id="formatDay">{this.state.fullTravelObj.countAdult + this.state.fullTravelObj.countChild + this.state.fullTravelObj.countInfant} </span>
            <span className="calenderMonth" data-test-id="formatMonth">{this.state.fullTravelObj.countAdult + this.state.fullTravelObj.countChild + this.state.fullTravelObj.countInfant === 1? "Traveller":"Travellers"}</span><br />
            <span className="calenderYear" style={{ fontWeight: "500", color: "white", display: "block", marginTop: "10px" }} data-test-id="formatYear">{this.state.fullTravelObj.travelStatus}</span>
          </div>
        ) : ''}
      </>
    )
  }
  calenderBoxUiChild=()=>{
    return(
      <Popover
           data-test-id="popover"
           open={this.state.isOpen}
           anchorEl={this.state.setAnchorAi}
           onClose={this.handleCloseCustomAi}
           anchorOrigin={{
             vertical: 'bottom',
             horizontal: 'center',
           }}
           transformOrigin={{
             vertical: 'top',
             horizontal: 'center',
           }}
         >
          {this.state.selectedOption==="One Way" && this.state.selectedTripType === 'One Way'?
        <div className="boxCalenderDone111" style={{borderRadius:"10px", width:"auto", boxShadow:"rgba(0, 0, 0, 0.25) 0px 0px 10px", zIndex: 9999, background:"#fff" }}>
<div className="datepikerTop111">
<div className="calTitle" style={{padding:"12px 20px", width:"auto"}}>
<Typography  variant="h4" ><span className="font14px">{this.state.startDate?this.formatDate(this.state.startDate):"Departure Date"}</span></Typography>
</div>

<div className="singleCalender calenderBoox">
<SingleCalender state={this.state} month={1} data-test-id="calenderBlock1" handleDateChange={this.handleDateChange1} />
</div>

<Box className="buttonBoxapply111">
<Button style={{color:"rgba(23, 100, 160, 1)", fontSize:"16px", textTransform:"capitalize", fontWeight:"700"}} data-test-id="handleCancel" onClick={this.handleCancel}>Cancel</Button>&nbsp;&nbsp;&nbsp;&nbsp;
<Button style={{background:"rgba(23, 100, 160, 1)", color:"#fff", fontSize:"16px", textTransform:"capitalize",fontWeight:"700"}} data-test-id="handleApply" onClick={this.handleApply}>&nbsp;Apply&nbsp;</Button>
</Box>
</div>

  </div>
  : <div className="boxCalenderDone111" style={{borderRadius:"10px", width:"auto", boxShadow:"rgba(0, 0, 0, 0.25) 0px 0px 10px", background:"#fff" }}>
<div className="datepikerTop111">
<div className="calTitle" style={{width:"auto"}}>
<Typography  variant="h4" ><span className="font14px" data-test-id= "changeDeparureDate">{this.state.startDate?this.formatDate(this.state.startDate):"Departure Date"}</span>&nbsp;-&nbsp;<span className="font14px">{this.state.endDate1?this.formatDate(this.state.endDate1):<>Select Return Date <span className="fontWee400">(save more on two ways)</span></>}</span></Typography>
</div>

<div className="calenderBoox">
<Calender state={this.state} data-test-id="calenderBlock" handleDateChange={this.handleDateChange} />
</div>

<Box className="buttonBoxapply111">
<Button style={{color:"rgba(23, 100, 160, 1)", fontSize:"16px", textTransform:"capitalize", fontWeight:"700"}} data-test-id="handleCancel" onClick={this.handleCancel}>Cancel</Button>&nbsp;&nbsp;&nbsp;&nbsp;
<Button style={{background:"rgba(23, 100, 160, 1)", color:"#fff", fontSize:"16px", textTransform:"capitalize",fontWeight:"700"}} data-test-id="handleApply" onClick={this.handleApply}>&nbsp;Apply&nbsp;</Button>
</Box>
</div>

</div> }
          </Popover>
    )
  }
  getFlightTypeHeader = () => {
    return (
      <div className="catalogue-header">
        
        <Grid onClick={(event) => this.handleTripClick(event)}  data-test-id="trip-type" item xs={12} sm={10} md={3} lg={2} className="catalogue-header-box trip-type-box" style={{ paddingLeft: '16px', paddingTop: '10px',position:'relative', cursor: 'pointer' }}>
        {
            this.state.errorResSearch &&
            <span className="imgErrorBox error_message" style={{width:'fit-content',top:0,fontSize:'14px',fontWeight:'600',margin:'-32px 0px 14px 0px',position:'absolute'}}>
              {this.state.errorResSearch}
            </span>
          }
          <div style={{ display: "flex", alignItems: 'center', width: '100%', cursor: 'pointer' }}>
            <Typography className="catalogue-heading">
              TRIP TYPE
            </Typography>
            <ExpandMoreIcon />
          </div>
          <Menu
            marginThreshold={72}
            data-test-id="handleCloseTripType"
            id="simple-menu"
            anchorEl={this.state.anchorElTripType}
            PaperProps={{
              className: "menuTrip"
            }}

            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            
            open={this.state.tripOpen}
            onClose={this.handleClose}
            keepMounted

          >
            <MenuList id="split-button-menu" autoFocusItem >
              {this.state.tripOptions.map((option, index) => (
                <MenuItem
                  data-test-id={`trip-option${index}`}
                 
                  key={index}
                  selected={option.tripSelected}
                  onClick={() => this.handleSelectTriptype(index)}
                  className="catalogue-menu"

                >
                  {option.tripType}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
          <Typography className="calenderDay">{this.state.selectedTripType}</Typography>
        </Grid>
        <Grid item xs={12} sm={5} md={4} lg={3} style={{position:"relative",}}>
        <TextField
        autoComplete="off"
          onBlur={()=> setTimeout(()=>{
            this.handleClose()
          },300)}
          name="from"
          onChange={this.handleInputChange}
          style={{ cursor: "pointer" }}
          label={(
            <>
             <span className="catalogue-heading">From</span>

              {this.formInputText()}
            </>
          )}
          variant="outlined"
          placeholder={"Enter city/ Airport name"}
          data-test-id="inputCityFrom"
          value={this.state.inputApiSearchFrom}
          onClick={this.handleClick}
          fullWidth
          InputProps={{classes: {
              input: "catalogue-header-box",
              root: "custom-input-root",
            },

          }}
          className="catalogue-header-box"
          InputLabelProps={{
            className: "fontFrom fontLabel",
            shrink: true,
            style: { transform: "translate(14px, 7px)", cursor: "pointer", color: "#000", fontWeight: "700", fontSize: "16px", width: "100%" }
          }}
        />
        { (this.state.filteredItems.length> 0 && this.state.inputApiSearchFrom && Boolean(this.state.anchorEl)) &&
         <Box className="yscrollwindow fromDropdown" sx={{ width: { xs: "94%", sm: "94%", md: "100%", lg: "100%" } }}         style={{ padding: "10px" }}>
          {this.state.filteredItems.map((itemObj: SearchTextObj, index: number) => (
            <Box data-test-id = {`fromMenu${index}`} style={{ borderBottom: "1px solid rgba(226, 232, 240, 1)" }} key={index} onClick={() => this.handleSingleClose(itemObj)}>
              <Box className="highlight-item" style={{ paddingTop: "10px", width: "100%",cursor:'pointer' }}>
                <Grid container spacing={2} 
                style={{display:'flex',
                justifyContent:"space-between",
                padding:"12px"}}>
                  <Box 
                  style={{display:'flex',gap:'10px'}}> <Grid item> <FlightTakeoffIcon style={{ color: "rgba(148, 163, 184, 1)" }} /> </Grid>
                  <Grid item >
                    <Typography style={{ color: "#000", paddingBottom: "12px", fontWeight: "600", fontSize: "12px", }} variant="body1">{itemObj.state}</Typography>
                    <Typography style={{ color: "rgba(100, 116, 139, 1)", fontSize: "12px", paddingBottom: "10px", fontWeight: "600" }} variant="body1">{itemObj.flight}</Typography>

                  </Grid></Box>
                 
                  <Grid item>
                    <Typography style={{ color: "rgba(100, 116, 139, 1)", fontSize: "12px", fontWeight: "600" }}
                      variant="body1">{itemObj.stateCode}</Typography> </Grid>
                
              </Grid>
           
           
              </Box>
            
            
            </Box>
          ))}
        </Box>
         } 
        <Box
         data-test-id="reverseFunct" 
         onClick={this.reverseFunct} 
         className="reversChangeIcon">
              <SyncAltIcon style={{color:"rgb(23, 100, 160)", 
              cursor:"pointer"}} />
        </Box></Grid>
        <Grid 
        item 
        xs={12}
         sm={5} 
         md={4}
          lg={3} className="inputCity" style={{position:'relative'}}><TextField
            name="to"
            onBlur={()=> 
              
              setTimeout(()=>{this.handleClose()
            },
            300)}
            
            label={(
              <>
              <span className="catalogue-heading">To</span>
             {this.toInputText()}
              </>
            )}
            onChange={this.handleInputChangeTo}
            value={this.state.inputApiSearchTo}
            data-test-id="inputCityTo"
            onClick={this.handleClickTo}
            variant="outlined"
            autoComplete = "off"
            placeholder={"Enter city/ Airport name"}
            fullWidth
            className="catalogue-header-box"
            InputProps={{
              classes: {
                root: "custom-input-root",
                input: "catalogue-header-box",
              }
            }}
            InputLabelProps={{
              className:"fontFrom fontLabel",
              shrink: true,
              style: {overflow:"hidden", height:"100%", transform: "translate(14px, 7px)", color:"#000", fontWeight:"700",fontSize: "16px",width:"100%"}
          }}
          />
          {(this.state.filteredItemsTo.length >0 && this.state.inputApiSearchTo && Boolean(this.state.anchorElTo)) &&  <Box sx={{ width: { 
            xs: "94%", sm: "94%", md: "95%",
           lg: "96%" } }} className="yscrollwindow fromDropdown" style={{ padding: "10px" }}>
            {this.state.filteredItemsTo.map((item: SearchTextObj, index: number) => (
              <Box data-test-id = {`toMenu${index}`} style={{ borderBottom: "1px solid rgba(226, 232, 240, 1)" }} key={index} onClick={() => this.handleCloseTo(item)}>
                <Box style={{ width: "100%", paddingTop: "10px" }} className="highlight-item">
                  <Grid container spacing={2} style={{display:'flex',justifyContent:"space-between",padding:"12px"}}>
                    <Box style={{display:'flex',gap:'10px'}}>
                      <Grid item>
                        <FlightTakeoffIcon style={{ color: "rgba(148, 163, 184, 1)" }} />
                      </Grid>
                      <Grid item>
                        <Typography style={{ color: "#000", fontSize: "12px", paddingBottom: "12px", fontWeight: "600" }} variant="body1">{item.state}</Typography>
                        <Typography style={{ color: "rgba(100, 116, 139, 1)", fontSize: "12px", paddingBottom: "10px", fontWeight: "600" }} variant="body1">{item.flight}</Typography>
                      </Grid>
                    </Box>
                    <Grid item>
                      <Typography style={{ color: "rgba(100, 116, 139, 1)", fontSize: "12px", fontWeight: "600" }} variant="body1">{item.stateCode}</Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            ))}
          </Box>}
        </Grid>
        <Grid item xs={12} sm={this.state.selectedTripType === "Round Trip" ? 5 : 10} md={ 4} lg={3}>
          <TextField style={{cursor:"pointer"}} name="departureDate"
            className="catalogue-header-box"
            
            label={(
              <div style={{ display: 'flex', flexDirection: 'column',  gap: '5px' }}>

                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: '100%' }}>
                  <span className="catalogue-heading"> Departure Date</span>
                  <ExpandMoreIcon style={{ color: "white" }} />

                </div>
                {this.state.startDate ? (
                  <div >

                    <span className="calenderDay">{this.formatDatePart(this.state.startDate, "day")} </span>
                    <span className="calenderMonth" >{this.formatDatePart(this.state.startDate, "month")}</span><br />
                    <span className="calenderYear" style={{ display: "block", marginTop: "10px" }}>{this.formatDatePart(this.state.startDate, "year")}</span>
                  </div>
                ) : ''}
              </div>

            )}
            onClick={(event) => {
              this.togglePicker('startDate',event)
            }}

            data-test-id="startDate"
            placeholder={this.state.startDate ? "" : "Departure date"}
            variant="outlined"
            fullWidth
            InputProps={{
              classes: {

                root: "custom-input-root",
                input: "catalogue-header-box",
              },

            }}
            InputLabelProps={{

              className: "fontLabel",
              shrink: true,
              style: { transform: "translate(14px, 7px)", color: "#000", fontWeight: "700", fontSize: "16px" }
            }}
          />   
        </Grid>
        {
           this.calenderBoxUiChild()
        }
        {(this.state.selectedTripOption === 'round_trip' || this.state.selectedTripType === 'Round Trip') &&
          <Grid item xs={12} sm={5} md={ 4} lg={3} style={{ position: "relative" }}>
            <TextField
              style={{cursor:"pointer"}}
              disabled={this.state.selectedTripType !== "Round Trip"}
              className="catalogue-header-box"
              name="returnDate"
              label={(
                <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                  <div style={{ display: "flex", alignItems: "center", 
                  justifyContent: "space-between", width: '100%' }}>

                    <span className="catalogue-heading">Return Date</span>
                    <ExpandMoreIcon style={{ color: "white" }} />
                  </div>
                  {this.state.endDate1 ? (
                    <div>
                      <span className="calenderDay" data-test-id="formatDay">{this.formatDatePart(this.state.endDate1, "day")} </span>


                      <span className="calenderMonth" data-test-id="formatMonth">{this.formatDatePart(this.state.endDate1, "month")}</span><br />
                      <span className="calenderYear" style={{ display: "block", marginTop: "10px" }} data-test-id="formatYear">{this.formatDatePart(this.state.endDate1, "year")}</span>
                    </div>
                  ) : ''}
                </div>
              )}
              onClick={(event) => this.togglePicker('endDate',event)}
              data-test-id="endDate"
              placeholder={this.state.endDate1 ? "" : `Return date
            `}
              variant="outlined"
              fullWidth

              InputProps={{
                classes: {
                  root: "custom-input-root",


                  input: "catalogue-header-box",
                },

              }}
              InputLabelProps={{

                className: "fontLabel",
                shrink: true,
                
                style: { transform: "translate(14px, 7px)", color: "#000", fontWeight: "700", fontSize: "16px" }
              }}
            />
          </Grid>}

        <Grid style={{ position: 'relative' }} item  xs={12}
         sm={10} 
         md={3}
          lg={3}>
          <TextField
            name="traveller"
            label={(
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between"}}>

                <span className="catalogue-heading">Nationality</span>
                <ExpandMoreIcon style={{ color: "rgba(23, 100, 160, 1)" }} />
                {this.nationalInputText()}
              </div>

            )}
            placeholder={"Select Nationality"}
            value={""}
            data-test-id="handleClickNationality"
            variant="outlined"
            fullWidth

            onClick={this.handleClickNationality}
            style={{ width: "100%", overflow: "hidden",cursor:'pointer',background:"#1764A080",borderRadius:'8px'  }}
            InputProps={{
              classes: {
                root: "custom-input-root",
                input: "catalogue-header-box",
              }
            }}
            InputLabelProps={{
              className:"fontFrom fontLabel",
              shrink: true,
              style: {overflow:"hidden" , height:"100%", transform: "translate(14px, 7px)", color:"#000", fontWeight:"700",fontSize: "16px",width:"100%"}
          }}
          />
          <Menu
            marginThreshold={72}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            data-test-id="anchorElNationalClass"
            className="menuFrom"
            id="simple-menu"
            anchorEl={this.state.anchorElNationalClass}
            PaperProps={{
              style: {
                width: 300,
                borderRadius: "10px",
                marginTop: 40,
              }
            }}
            open={Boolean(this.state.anchorElNationalClass)}
            onClose={this.handleNationalityClass}
            keepMounted

          >
             {this.state.nationalities?.map((item,index)=> (
            <MenuItem data-test-id={`nationality_option${index}`} onClick={() => this.handleSelectNationality(item.country_name)}>{item.country_name}</MenuItem>
          ))}     </Menu>
        </Grid>
        <Grid item xs={12} sm={10} md={4} lg={3} style={{ overflow: "hidden" }}>
          <TextField
            name="travellers & class"
            className="catalogue-header-box"
            placeholder={"Select no.of travellers & Class."}
            variant="outlined"
            label={(
              <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: '100%' }}>
                  <span className="catalogue-heading">Travellers & Class</span>
                  <ExpandMoreIcon style={{ color: "white" }} />
                </div>
                {this.travelInputText()}
              </div>

            )}

            
            fullWidth
           
            InputProps={{
              classes: {
                root: "custom-input-root",
                input: "catalogue-header-box",

              },
            }}
            value={""}
            data-test-id="handleClickClass"
            onClick={this.handleClickClass}
            style={{ width: "100%",cursor:"pointer" }}

            InputLabelProps={{
              className: "fontLabel",
              shrink: true,
              style: { transform: "translate(14px, 7px)", color: "#000", fontWeight: "700", fontSize: "16px" }
            }}
          />
          <Menu
          anchorEl={this.state.anchorElClass}
            marginThreshold={72}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            data-test-id="handleCloseClass"

            className="menuFrom dummtdes"

            id="simple-menu"

            

            PaperProps={{
              style: {
                width: 600,
                borderRadius: "10px"
              }
            }}
            open={Boolean(this.state.anchorElClass)}
            onClose={this.handleCloseClass}
            keepMounted

          >

            <Box style={{ padding: "10px" }}>
              <Box className="classTravelHead">
                <Typography style={{fontSize:"12px", color:"#000",fontWeight:700}} variant="body1">{this.state.countAdult} {this.state.countAdult<=1?"Adult":"Adults"} , {this.state.countChild} {this.state.countChild<=1?"Child":"Children"}, {this.state.countInfant}  {this.state.countInfant<=1?"Infant":"Infants"}</Typography>
                <Typography style={{ fontSize: "12px", color: "rgba(100, 116, 139, 1)", }} variant="body1">{this.state.travelStatus}</Typography>
              </Box>
              <Box style={{ width: "100%", borderBottom: "1px solid rgba(226, 232, 240, 1)", marginTop: "10px" }}></Box>
              <Box style={{ width: "100%", }}>

                <Grid container spacing={2}>
                  <Grid item xs={6} sm={4}>

                    <Box sx={{ padding: { xs: "10px", sm: "10px", md: "19px 10px 0px 10px" }, textAlign: "center" }}>
                      <Typography style={{ fontSize: "24px", color: "#000", fontWeight: "700" }} variant="body1">   {this.state.countAdult} {this.state.countAdult<=1?"Adult":"Adults"}</Typography>
                      <Typography style={{ fontSize: "12px", color: "rgba(100, 116, 139, 1)", padding: "10px 0 20px 0" }} 
                      variant="body1">(Aged 18+years) </Typography>
                      <Box sx={{ padding: { xs: "6px 12px", sm: "6px 16px", md: "6px 20px" } }} className="countDivBox">
                        <button className="borderNoneCount" data-test-id="decreaseCount" onClick={this.decreaseCount}><RemoveIcon /></button>
                        <div className="countShowText">{this.state.countAdult}</div>
                        <button className="borderNoneCount" data-test-id="increaseCount" onClick={this.increaseCount}><AddIcon /></button>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Box sx={{ textAlign: "center", padding: { xs: "10px", sm: "10px", md: "19px 10px 0px 10px" } }}>

                      <Typography style={{ fontSize: "24px", color: "#000", fontWeight: "700" }} variant="body1">  {this.state.countChild} {this.state.countChild<=1?"Child":"Children"}</Typography>
                      <Typography style={{ fontSize: "12px", color: "rgba(100, 116, 139, 1)", padding: "10px 0 20px 0" }} 
                      variant="body1">(Aged 2-12 years)  </Typography>
                      <Box sx={{ padding: { xs: "6px 12px", sm: "6px 16px", md: "6px 20px" } }} className="countDivBox">
                        <button className="borderNoneCount" data-test-id="decreaseCountChild" onClick={this.decreaseCountChild}><RemoveIcon /></button>
                        <div className="countShowText">{this.state.countChild}</div>
                        <button className="borderNoneCount" data-test-id="increaseCountChild" onClick={this.increaseCountChild}><AddIcon /></button>

                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Box sx={{ textAlign: "center", padding: { xs: "10px", sm: "10px", md: "19px 10px 0px 10px" } }}>
                      <Typography style={{ fontSize: "24px", color: "#000", fontWeight: "700" }} variant="body1">   {this.state.countInfant} {this.state.countInfant<=1?"Infant":"Infants"}</Typography>
                      <Typography style={{ fontSize: "12px", color: "rgba(100, 116, 139, 1)", padding: "10px 0 20px 0" }} variant="body1">(Under 2 years)  </Typography>
                      <Box sx={{ padding: { xs: "6px 12px", sm: "6px 16px", md: "6px 20px" } }}
                       className="countDivBox">

                        <button className="borderNoneCount" data-test-id="decreaseCountChildInfant" onClick={this.decreaseCountChildInfant}><RemoveIcon /></button>
                        <div className="countShowText">{this.state.countInfant}</div>
                        <button className="borderNoneCount" data-test-id="increaseCountChildInfant" onClick={this.increaseCountChildInfant}><AddIcon /></button>

                      </Box>
                    </Box>
                  </Grid>

                  {
                    this.state.error &&
                    <Grid item sm={12}>
                      <Box className="ErrorMsg" sx={webStyleLanding.errorMsgforCount}>
                        <span className="imgError " style={{ width: "5%", marginRight: "5px" }}>
                          <React.Suspense fallback={<CircularProgress />}>
                            <img src={errorIcon} />
                          </React.Suspense>
                        </span> <span style={{ width: "95%" }}>

                          If you want to add more than 9 travellers please contact email <span style={{ textDecoration: "underline" }}>www.doolwa.com</span>
                        </span>
                      </Box>
                    </Grid>
                  }

                  <Box sx={{textAlign: "center", 
                    width: "100%", padding: "0px 0 0px 0",
                  }}>
                    <Typography variant="body1" style={{ fontSize: "24px", color: "#000", fontWeight: "700" }} > Travel Class</Typography>
                  </Box>
                  <Grid item sm={12} data-test-id="allbutton">


                    {this.allButtonList()}
                  </Grid>

                  <Box className="buttonBoxapply" style={{ padding: "10px", margin: "0px 10px" }}>
                    <Button style={{ color: "rgba(23, 100, 160, 1)", fontSize: "16px", textTransform: "capitalize", fontWeight: "700" }} data-test-id="handleCancelClassTravel" onClick={this.handleCancelTravel}>Cancel</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                    <Button style={{ background: "rgba(23, 100, 160, 1)", color: "#fff", fontSize: "16px", textTransform: "capitalize", fontWeight: "700" }} data-test-id="handleDone" onClick={this.handleDone}>&nbsp;Done&nbsp;</Button>
                  </Box>
                </Grid>
              </Box>
            </Box>
          </Menu>
        </Grid>
      </div>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { dateCustomPrices, offersList } = this.state;
    // Customizable Area End
    return (
      //Merge Engine DefaultContainer
      // Customizable Area Start
      <><Loader loading={this.state.loading} />
        <div style={{ background: '#E9F3FC', height: '100%', overflowY: 'scroll' }}>
          <Box sx={webStyle.headerContainer} className="header-container" data-test-id='header-oneWay-menu'>
            <HeaderMenuBlock navigation={this.props.navigation} id={""} />
            <Grid container spacing={0} 
            className="searchFrom searchInput"
             style={{ position: "relative", paddingLeft: '10px', paddingRight: "10px" }}>
              {this.getFlightTypeHeader()}
              <Grid item xs={12}>
                <Box style={{ display: "flex", justifyContent: "center", margin: "20px 0" }}>
                  <Button
                    onClick={() => this.handleSearch()}
                    type="submit"
                    variant="contained"
                    data-test-id="search-btn"
                    style={{
                      backgroundColor:  '#1764A0',
                      fontFamily: "Inter, sans-serif",
                      fontWeight: '700',
                      fontSize: '16px',
                      textTransform: 'none',
                      color: "white",
                      width: '327px',
                      height: '56px',
                      
                    }}
                  >
                    Search Flights
                  </Button>
                </Box>
              </Grid>
            </Grid>

          </Box>

          <Box sx={webStyle.contentContainer}>
            <div  style={{
              position: 'absolute',
              top: '-100px',
              width: '100%', display: 'flex',

              gap: '21px',
              justifyContent: 'space-around',
            }} className="content-container">
              <Button  data-test-id="filter-icon" style={{ display: 'none' }} onClick={() => this.handleFiltersToggle()}  className="filter-icon-btn ">
                <Tune className="catalogue-heading" />
              </Button>
              <div className="desktop-filter" data-test-id="filter-block"><Filteritems
             
             clearAllFunct={this.clearAllFunct}
             inputCityFrom ={this.state.searchText.state}
             selectedTripType={"oneWayTrip"}
             inputCityTo ={this.state.toText.state}
              airlinsFunct={this.airlinsFunct}

              airLinesData={this.state.airLinesData}
               departureTimePrice={this.state.departureTimePrice}

              handleDepartureTimeSelect={this.handleDepartureTimeSelect}

              handleArrivalTimeSelect={this.handleArrivalTimeSelect}

              arrivalTimePrice={this.state.arrivalTimePrice}
               timePriceArray={this.state.timePriceArray}
              shownPopularFilters={this.state.shownPopularFilters}

              selectedPopularFilter={this.state.selectedPopularFilter}
                nonStopFunct = {this.handlePopulerFunct}

                onPriceRangeChange={this.handlePriceRangeChange}
                selectedPrice={this.state.selectedPrice}
                minPrice={this.state.minPrice}
                maxPrice={this.state.maxPrice}
                price_range={this.state.price_range} navigation={this.props.navigation} id={""} /></div>
              <Box sx={webStyle.detailsContainerC} className="details-container">
                <Typography className="flight-detail-heading" style={{ fontFamily: 'Inter, sans-serif', fontWeight: '700', fontSize: '32px', color: 'white' }}>Flights from <span style={{textTransform:"capitalize"}}>{this.state.searchText.state}</span> to <span style={{textTransform:"capitalize"}}>{this.state.toText.state}</span></Typography>
                <div data-test-id="price-calendar" style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  boxShadow: '0px 4px 10px 0px #00000029',
                  width: '100%',
                  background: 'white',
                 
                }}
                className="price-calender"
                >
                  <Box data-test-id="backward-btn" sx={{ boxShadow: "0px 4px 10px 0px #00000029", display: 'flex', justifyContent: 'center', alignItems: 'center', width: '46px', zIndex: '10' }} onClick={() => this.handlePrevClick()} >
                    <ArrowBackIos />
                  </Box>
                  <Grid innerRef={this.datePricesRef} container direction="row" alignItems="center" style={{ overflow: 'hidden', whiteSpace: 'nowrap', flexWrap: 'nowrap', height: '100%' }}>
                    {dateCustomPrices.map((item, index) => (

                      this.getDatesList(item , index)
                    ))}

                  </Grid>
                  <Box data-test-id="next-btn" sx={{  justifyContent: 'center',boxShadow: "0px 4px 4px 0px #00000029", display: 'flex', alignItems: 'center', width: '46px' }} onClick={() => this.handleNextClick()}>
                    <ArrowForwardIos />
                  </Box>
                </div>
                <Box style={{ width: '100%' }} data-test-id="flights-list" >
                  {this.state.flightAllDataArray.length===0 && <NotFound />}
                  {this.state.flightAllDataArray.map((item: FlightData, index: number) => (
                    this.getFlightsList(item, index)
                  ))}
                </Box>
              </Box>
            </div>
          </Box>
          <PriceModal hanldeSubmitPrice={this.hanldeSubmitPrice} navigateScreens={this.navigateScreens} convertFormatTime={this.convertFormatTime} data-test-id="pricemodal-oneway" state={this.state} DateComponent={this.DateComponent} handleClosePriceModal={this.handleClosePriceModal} handleFareOptionSelect={this.handleFareOptionSelect} />
          <Drawer
            PaperProps={{
              style: {
                height: '90vh'
              },
            }}
            anchor={"bottom"}
            variant="temporary"
            open={this.state.filtersDrawer}
            onClose={() => this.handleFiltersToggle()}
            data-test-id="filters-drawer"
          >
            <div data-test-id="filter-block"><Filteritems
             clearAllFunct={this.clearAllFunct}
           selectedTripType={"oneWayTrip"}
            departureTimePrice={this.state.departureTimePrice}
             handleDepartureTimeSelect={this.handleDepartureTimeSelect}
             handleArrivalTimeSelect={this.handleArrivalTimeSelect}
             arrivalTimePrice={this.state.arrivalTimePrice}
            timePriceArray={this.state.timePriceArray}
            inputCityFrom ={this.state.searchText.state}
            inputCityTo ={this.state.toText.state}
            airlinsFunct={this.airlinsFunct}
             airLinesData={this.state.airLinesData}
            shownPopularFilters={this.state.shownPopularFilters}
            selectedPopularFilter={this.state.selectedPopularFilter}
             nonStopFunct = {this.handlePopulerFunct}
             onPriceRangeChange={this.handlePriceRangeChange}
              selectedPrice={this.state.selectedPrice}
              minPrice={this.state.minPrice}
              maxPrice={this.state.maxPrice}
              price_range={this.state.price_range} navigation={this.props.navigation} id={""}
              data-test-id="handlePriceRangeChange"
               /></div>
          </Drawer>
        </div>
      </>
      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start

const webStyle = {
  textFlightd:{
    fontFamily: "Inter, sans-serif",
    fontSize: '12px',
  },

 
  
  headerContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "56px",
    overFlowX: 'hidden',
    height: {
      xs: '970px',
      sm: '930px',
      md: '680px',
      lg: '590px'
    },
    background: "linear-gradient(180deg, #013B68 84.25%, #0175D0 100%)",
  },
  
  heading: {
    fontFamily: "Inter, sans-serif",
    fontWeight: '700',
    fontSize: '24px',
    marginLeft: '32px',
    marginTop: '32px'
  },
  contentContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
    width: "100%",
    height: '100%',
    background: "#E9F3FC",
    gap: '21px',
    position: 'relative'

  },
  
  detailsContainerC: {
    display: "flex",
    flexDirection: "column",
    gap: '10px',
    flexWrap: "wrap",
    width: "60%",
    height: '100%',
    background: "transparent",
    borderRadius: '8px',
    marginRight: '21px'
  },
 
  

};
const flightItemStyle = {
  container: {
    display: 'flex',
    marginBottom: '10px',
    background: 'white',
    boxShadow: '0px 4px 10px 0px #00000029',
    flexDirection: 'column' as 'column',
    borderRadius: '5px',
   

  },
  durationColumn: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '16px',
    flexDirection: 'column' as 'column',
    
  },
  topRow: {
   
    borderBottom: '2px dashed #1764A0',
    paddingLeft: '32',
    paddingTop: '20',
    paddingBottom: '20',
    paddingRight: '24',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  topRow1: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '32',
    paddingTop: '20',
    paddingBottom: '20',
    paddingRight: '24'
  },
  bottomRow: {
    display: 'flex',
   
    paddingTop: '14',
    paddingBottom: '14',
    paddingRight: '25',
    justifyContent: 'space-between',
    alignItems: {
      xs:"start",
      sm:"center",
      md:"center",
      lg:"center"
    },
    paddingLeft: '24',
  },
  bottomRow1: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '5',
    paddingBottom: '5',
    justifyContent: 'space-between',
   
    gap:"5px"
  },
  flightHeadingC: {
    fontFamily: "Inter, sans-serif",
    fontWeight: '700',
    fontSize: '16px'
  },
  column: {
    flexDirection: 'column' as 'column',
    alignItems: 'flex-start',
    display: 'flex',

   
    gap: '16px'
  },
  flightDetails: {
    display: 'flex',

    alignItems: 'center',
  },
  placeTextC: {
    fontFamily: "Inter, sans-serif",
    fontWeight: '600',
    fontSize: '16px',
    color: '#64748B'
  }
};
const webStyleLanding = {
  errorMsgforCount: {
    margin: "0px 10px",
    borderRadius: "5px",
    fontSize: "12px",
    borderLeft: "6px #c10f0ff0 solid",
    background: " #ed7e7e3d",
    fontFamily: "Inter, sans-serif",
    color: "#c12121",
    display: "flex",
    lineHeight: "24px",
    padding: "20px 15px",
    alignItems: "center"
  },
}
// Customizable Area End