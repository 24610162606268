Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.apiMethodTypePost = "POST";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.oneWayEndPoint="bx_block_cfgdsavinodeintegration/flights?"
exports.baggageText = "Baggage";
exports.icon = ">";
exports.tripType = " TRIP TYPE";
exports.ageCategory = "Aged 18+years";
exports.yearCategory = "Aged 2-12 years";
exports.underYear = "Under 2 years";
exports.economy = "ECONOMY";
exports.fareOption = "More fare options available for your mutlicity";
exports.flightNumber = "Flight No";
exports.baggage = "Baggage";
exports.bookNow = "Book Now";
exports.continue = "Continue";
exports.airportSearchEndPoint="bx_block_cfgdsavinodeintegration/get_airport_list?key="
exports.moreFairRoundTrip = "More fare options available for your roundtrip";
exports.priceAPiEndPoint ="bx_block_cfgdsavinodeintegration/create_price"
exports.nationalitiesEndpoint = "bx_block_landingpage2/nationalities"
exports.reguxDuration = /(\b\d+\s*days?\b[, ]*)?(\b\d+\s*hours?\b[, ]*)?(\b\d+\s*minutes?\b)?/
// Customizable Area End