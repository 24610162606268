import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Message } from "../../../framework/src/Message";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading:boolean;
  appliedCoupon:any;
  selectedPrice: number,
      minPrice: number,
      maxPrice: number,
      price_range: {
        min: number,
        max: number
      },
      minDuration: number;
      maxDuration: number;
      selectedDuration: number;
      dataAllPackage:any;
      ErrorMsg:any;
      startDate:any;
      count:any;
      cancelData:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AllPackageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getPriceFilterPackageApiCallId="";
  getDurationPackageApiCallId="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading:false,
      cancelData:[],
      ErrorMsg:"",
      count:0,
      startDate:"",
      dataAllPackage:[],
      selectedPrice: 1000,
      minPrice: 0,
      maxPrice: 299000,
      price_range: {
        min: 0,
        max: 0
      },
      minDuration: 1,  
      maxDuration: 7,   
      selectedDuration: 2,
      appliedCoupon:null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    
    // Customizable Area End
  }
  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestsCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if (responseJson && !responseJson.errors) {
        if(apiRequestsCallId === this.getDurationPackageApiCallId){
          this.filterDurationPackageRes(responseJson)
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }
 
  handlePriceChange = (event:any, newValue:any) => {
    this.setState({ selectedPrice: newValue });

  };

  
  handleDurationChange = (event:any, newValue:any) => {
    this.setState({ selectedDuration: newValue });
  };
  handleChildDataFn = (data:any, count:any, startDate:any) => {
    if(data === "Package not found, Please try searching for something else!"){
      this.setState({ErrorMsg:data,startDate:"",dataAllPackage:[],loading:false})

    }else{
      this.setState({cancelData:data})
      let dataAll = data.map((item:any, index:any)=>{
        return {
          "id":item.attributes.id,
          "image":item.attributes.image,
          "name":item.attributes.name,
          "price": item.attributes.price,
          "totalprice": item.attributes.price * count,
          "package_type": item.attributes.package_type,
          "day":item.attributes.day,
          "min_max_night":item.attributes.min_max_night,
          "min_max_price":item.attributes.min_max_price,
          "package_services":item.attributes?.main_services,
        }
      })
      this.setState({dataAllPackage:dataAll,startDate:startDate,count:count,ErrorMsg:"", minPrice: dataAll[0]?.min_max_price.min_price, loading:false,
       maxPrice:dataAll[0]?.min_max_price.max_price,
       selectedPrice:dataAll[0]?.min_max_price.max_price,
       minDuration:dataAll[0]?.min_max_night.min_night,
       maxDuration:dataAll[0]?.min_max_night.max_night,
       selectedDuration:dataAll[0]?.min_max_night.max_night
    })
    }
   
};
handleClickBestDetails = (itemEle:string) => {
  localStorage.setItem("startDatePackage", this.state.startDate)
  const token = localStorage.getItem('authToken')
  if(!token){
    localStorage.setItem("PackageDetailID", JSON.stringify(itemEle))
    setTimeout(() => {
      this.props.navigation.navigate("EmailAccountLoginBlock")
    },1000)
  }else{
    this.props.navigation.navigate("PackageDetail", { id: itemEle})
  }
};


filterDurationPackageRes=(responseJson:any)=>{
  if(responseJson.data.length>0){
    let dataAllCustom = responseJson.data.map((item:any,index:any)=>{
      return {
        "id":item.attributes.id,
        "image":item.attributes.image,
        "day":item.attributes.day,
        "package_services":item.attributes?.itenanries?.data?.[index]?.attributes?.package_services,
        "name":item.attributes.name,
        "price": item.attributes.price,
        "totalprice": item.attributes.price * this.state.count,
        "package_type": item.attributes.package_type,
    
      }
    })
    this.setState({dataAllPackage:dataAllCustom,startDate:this.state.startDate,ErrorMsg:"", loading:false})
  }else{
    this.setState({ErrorMsg:"Please try searching for something",dataAllPackage:[],loading:false})
  }
  
}

handleApply=()=>{
  this.setState({loading:true})
  const PCheaders = {
    "Content-Type": configJSON.exampleApiContentType,
  };

  const getFilterDuration = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getDurationPackageApiCallId = getFilterDuration.messageId;
let Url = `filter[duration][min_night]=${this.state.minDuration}&filter[duration][max_night]=${this.state.selectedDuration+1}&filter[price][min]=${this.state.minPrice}&filter[price][max]=${this.state.selectedPrice}`;
getFilterDuration.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.apiEndPointPriceFilter+Url
  );
  getFilterDuration.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );
  getFilterDuration.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(PCheaders)
  );
  
  runEngine.sendMessage(getFilterDuration.id, getFilterDuration);
  return true;
}
cancelButton=()=>{
  this.setState({loading:true})
  setTimeout(() => {
    this. handleChildDataFn(this.state.cancelData, this.state.count, this.state.startDate)
  },500)
 

}
  // Customizable Area End
}
