import React from "react";

import {
    Box,
    Button,
    Typography,
    // Customizable Area Start
    Grid,
    CardMedia,
    Card,
    List,
    ListItem,
    ListItemText,
    Snackbar
    // Customizable Area End


} from "@material-ui/core";
// Customizable Area Start
import { image1, image2, couponIcon } from "./assets";
import "../assets/style.css"
const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 2, 
      partialVisibilityGutter: 150,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2,
      partialVisibilityGutter:0,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter:0,
    }
  };
// Customizable Area End

import PackageDetailController, {
    Props,
} from "./PackageDetailController.web";
import PackageSearch from "./PackageSearch.web";
import Carousel from "react-multi-carousel";
import { CheckCircleOutline } from "@material-ui/icons";

export default class PackageDetail extends PackageDetailController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
   

    // Customizable Area End

    render() {
      
        return (
            // Customizable Area Start

            <>
             <Snackbar open={this.state.toastOpen}  anchorOrigin={{ vertical: 'top', horizontal: 'right'}} onClose={()=> this.setState({toastOpen:false})} autoHideDuration={3000}>
                    <Typography style={{padding:'8px',background:"rgb(23, 100, 160)",color:'white',borderRadius:'4px',display:'flex',alignItems:'center',gap:'10px'}}>
                        <CheckCircleOutline /> {this.state.ApiResponseMessage}
                    </Typography>
                </Snackbar>
                <PackageSearch data-test-id="PackageSearch" navigation={this.props.navigation} id={""} package={""} onSendData={this.handleChildData}  />
                <Box sx={{ padding: { xs: "10px 10px", sm: "10px 10px", md: "10px 30px", lg: "10px 60px" }, }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <div className="headTopDetail">
                                <Typography variant="h5" data-test-id="mainHeading" className="headingdetail">
                                    {this.state.singlePackageData?.attributes.name}
                                </Typography>

                            </div>



                        </Grid>
                    </Grid>
                    <Box className="boxShadowDetail">
                    <Grid spacing={2} container>
                            {this.state.singlePackageData?.attributes.itinanary_all_images.length===4?<>
                                <Grid item xs={12} sm={12} md={4}>
                                <Card className="image-card">
                                    <CardMedia
                                        component="img"
                                        alt="Beach huts"
                                        image={this.state.singlePackageData?.attributes.itinanary_all_images[0]}
                                        title="Beach huts"
                                    />
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <Card className="image-card sub-image" style={{ marginBottom: "10px" }}>
                                    <CardMedia
                                        component="img"
                                        alt="Temple"
                                        image={this.state.singlePackageData?.attributes.itinanary_all_images[1]}
                                        title="Temple"
                                    />
                                </Card>
                                <Card className="image-card sub-image">
                                    <CardMedia
                                        component="img"
                                        alt="Cityscape"
                                        image={this.state.singlePackageData?.attributes.itinanary_all_images[2]}
                                        title="Cityscape"
                                    />
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <Card className="image-card">
                                    <CardMedia
                                        component="img"
                                        alt="Beach huts"
                                        image={this.state.singlePackageData?.attributes.itinanary_all_images[3]}
                                        title="Beach huts"
                                    />
                                </Card>
                            </Grid>
                            </>:<>
                            {this.state.singlePackageData?.attributes.itinanary_all_images
    ?.slice(0, 4)
    .map((item: any, index: any) => (
                <Grid item xs={12} sm={this.getGridSize(this.state.singlePackageData?.attributes.itinanary_all_images.length)} key={index}>
                    <Card className="image-card">
                        <CardMedia
                            component="img"
                            alt={`Image ${index + 1}`}
                            image={item}
                            title={`Image ${index + 1}`}
                        />
                    </Card>
                </Grid>


            ))}
                   
                            </>
                            }
                           
                        </Grid>
                        <Typography variant="body1" className="itinerary">
                            ITINERARY
                        </Typography>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={8}>
                            <Box className="boxShadowDetail">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={3}>
                                        <Box>
                                            <Typography data-test-id="dayPlan" style={{ fontSize: "24px", fontWeight: 700, padding: "15px 0", textAlign: "center" }}>{ this.state.dayPlan.length} Day Plan</Typography>
                                            <ul className="dayplanUl" data-test-id="dayplanUl">
                                                {this.state.dayPlan.map((item: any,index:any) => (
                                                    <li  data-test-id={`scrollToItenary${index}`} onClick={() => this.scrollToItenary(item.id)} 
                                                    style={{cursor:"pointer", background: this.state.activeDay === item.id ? "rgba(23, 100, 160, 1)" : "rgb(233, 243, 252)", color: this.state.activeDay === item.id ? "#fff" : "#000" }}
                                                        key={item.id}>{item.date?item.date:item.value}
                                                    </li>
                                                ))}

                                            </ul>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={9} className="scrollable-container yscrollwindow" style={{ maxHeight: "500px", overflowY: "auto" }}>
                                        <div style={{ position: "relative" }}>
                                            <Box sx={{ position: { xs: "relative", sm: "relative", md: "relative", }, top: "11px", right: "0" ,
                                        display:"flex", zIndex:"99",
                                        justifyContent:{xs:"start",sm:"start",md:"end"} }}>
                                                <Button variant="outlined" style={webStyleDetail.ButtonStyle1}>
                                                    {this.state.singlePackageData?.attributes.day}
                                                </Button>
                                                &nbsp;&nbsp;
                                                <Button data-test-id="DownloadPdf"
                                                 onClick={() => window.open(this.state.singlePackageData?.attributes.download_pdf, '_blank')} 
                                                 variant="outlined" style={webStyleDetail.ButtonStyle2}>
                                                    Download PDF
                                                </Button>
                                            </Box>
                                           {
                                            this.state.itenaryData.map((item:any,index:any)=>(
                                                <div key={item.id} id={`itenary-${item.id}`} className="textPoMargin" style={{marginTop: this.state.itenaryData[0]?"-35px":""}}>

                                                 <Typography variant="h5" className="sectionTitle"   style={{ display: "inline" }}>
                                                <span className="textUprCapital" style={webStyleDetail.ButtonStyle}> { `${item.day.value} ${item.title}`}</span>
                                            </Typography>
                                            <div style={{width:"100%",display:"flex"}}>
                                            <div>
  <span style={{fontSize:"14px"}}>
    {this.state.expandedItems[item.id] 
      ? this.stripHtmlTags(item.description) 
      : this.stripHtmlTags(item.description).length > 100 
        ? `${this.stripHtmlTags(item.description).substring(0, 100)}...` 
        : this.stripHtmlTags(item.description) 
    }
  </span>
  {this.stripHtmlTags(item.description).length > 100 && ( 
    <span
    data-test-id={`toggleReadMore${item.id}`}
      style={{
        color: 'rgb(23, 100, 160)',
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        marginLeft: '5px',
        fontSize:"14px"
      }}
      onClick={() => this.toggleReadMore(item.id)} 
    >
      {this.state.expandedItems[item.id] ? 'Read Less' : 'Read More'}
    </span>
  )}
</div>

 

</div>

                                            <Grid container spacing={2}>
                                           <Grid item xs={12}>
                                           <Carousel 
          responsive={responsive}
                infinite={true}
                removeArrowOnDeviceType={['tablet', 'mobile']}
                containerClass="carousel-container"
                partialVisbile={false}
                arrows={item.image_url.length>3?true:false} 
                className={item.image_url.length>3?"":"coustomSlider"}
                >
            {item.image_url?.map((item:any, index: number) => (
              <div key={index} style={{ position: "relative",margin:"10px" }}>
                <img src={item} className="image-cardMulti" title={`Image ${index + 1}`} />
              </div>
            ))}
          </Carousel>
                                           </Grid>
                                               

                                               
                                            </Grid>
                                            <ul className="listDetailsUl" style={{paddingBottom:"30px"}}> 
                                           {this.state.singlePackageData?.attributes?.itenanries.data[index].attributes.package_services.map((item:any)=>(
 <li>{item.service_name}</li>
                                           ))}    
                                            </ul>
                                                </div>
                                            ))
                                           }
                                        </div>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>


                        <Grid item xs={12} sm={12} md={4}>
                            <Box className="boxShadowDetailCoupon">
                                <Box >
                                    <Box style={{ textAlign: "center" }}>
                                        <Typography style={{ fontSize: "24px", fontWeight: 700, padding: "15px 0" }}>Rs.{this.state.singlePackageData?.attributes.price.toLocaleString()}/Adult</Typography>
                                        <Button
                                            type="submit"
                                            data-test-id="handleSubmitOrderMgmtss"
                                            style={{
                                                background: "rgba(23, 100, 160, 1)",
                                                borderRadius: "5px",
                                                padding: "10px 35px",
                                                marginTop: "10px", color: "#fff",
                                                fontSize: "16px", textTransform: "capitalize",
                                                fontWeight: 700
                                            }}
                                        >Proceed To Payment</Button>
                                    </Box>
                                    <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                        <div style={{ width: "100%" }}>
                                            <Typography data-test-id="Coupons" variant="h6" style={{ padding: "20px 17px", fontSize: "16px", fontWeight: 700 }}>Coupons & Offers</Typography>
                                            <List>
                                                {this.state.couponsData?.map((coupon:any, index:any) => (
                                                    <ListItem key={coupon.id} divider >
                                                        <img src={couponIcon} />&nbsp;&nbsp;
                                                        <ListItemText className="textCoupon" primary={coupon.offer_code} />
                                                        <Button
                                                            variant="outlined"
                                                            style={{ color: "rgba(23, 100, 160, 1)", background: "none", border: "none" }}
                                                            data-test-id={`${"handleApplyCoupon"}${index}`}
                                                            onClick={() => this.handleApplyCoupon(coupon)}
                                                        >
                                                            <span style={webStyleDetail.textCouponApply}>
                                                                {this.state.appliedCoupon === coupon.offer_code ? (
                                                                    <>
                                                                        APPLIED <span style={{ marginLeft: '10px', cursor: 'pointer' }}>&times;</span>
                                                                    </>
                                                                ) : (
                                                                    'APPLY'
                                                                )}
                                                            </span>

                                                        </Button>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </div>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
let webStyleDetail = {
    ButtonStyle: {
        display: "inline-block",
        padding: "10px",
        fontSize: "14px",
        FontWeight: 700,
        lineHeight: "18px",
        marginTop: "12px",
        marginBottom: "12px",
        color: "white",
        borderRadius: "0",
        background: "rgba(23, 100, 160, 1)"
    },
    ButtonStyle1: {
        fontSize: "14px",
        fontWeight: 700,
        color: "#000",
        background: "#fff",
        border: "1px solid rgb(23, 100, 160)"
    },
    ButtonStyle2: {

        fontSize: "14px",
        fontWeight: 700,
        color: "white",
        background: "rgba(23, 100, 160, 1)"
    },
    textCouponApply: {
        fontSize: "16px", fontWeight: 700
    }
}

// Customizable Area End

