import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  coupnFunct: (objeValue: number) => void;
  totalAmount:any;
  taxesSurchargees:any;
  baseFare:any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  couponModal:boolean;
  appliedCoupon:any;
  offersData:any;
  offersObject:any;
  errorMsg:any;
  toastOpen:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CouponDiscountsController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      couponModal:false,
      toastOpen:false,
      appliedCoupon:null,
      offersData:[],
      offersObject:{},
      errorMsg:""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount();
    this.getCouponFunct()
    // Customizable Area End
  }

  // Customizable Area Start
  getOffersApiCallId="";
  applyApiCallId="";
  deleteApiCallId="";
  applyFunct=()=>{
    this.setState({couponModal:true,appliedCoupon:null})
  }
  closeModal = () => {
    this.setState({ couponModal: false });
  };
  handleApplyCoupon = (coupon:any) => {
    this.setState({ appliedCoupon: coupon.attributes.id });
    this.props.coupnFunct(coupon)
  };
  removeFunct=async(itemId:any)=>{
    let token = await getStorageData('authToken');
      const header = {
        "Content-Type": configJSON.exampleApiContentType,
        "Token":token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.deleteApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.couponRemoveApiEndPoint+itemId
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),"DELETE"
      );
      runEngine.sendMessage(requestMessage.id, requestMessage); 
  }
  handleSubmitOk=async()=>{
    let token = await getStorageData('authToken');
      const header = {
        "Content-Type": configJSON.exampleApiContentType,
        "Token":token
      };
      const httpBody = {
        "total_amount": this.props.totalAmount,
        "offer_id": this.state.appliedCoupon
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.applyApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.couponApplyAPiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);  
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson) {
        if (apiRequestCallId === this.getOffersApiCallId) {
          this.couponGetRes(responseJson)
        }
        if(apiRequestCallId === this.applyApiCallId){
         this.couponPostRes(responseJson)
        }
        if(apiRequestCallId === this.deleteApiCallId){
          this.DeletecouponPostRes(responseJson)
         }
       
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }
  getCouponFunct = () => {
    const PCheaders = {
      "Content-Type": configJSON.exampleApiContentType,
    };
    const getCouponMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getOffersApiCallId = getCouponMsg.messageId;
    getCouponMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.couponEndPoint
    );
    getCouponMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(PCheaders)
    );
    getCouponMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getCouponMsg.id, getCouponMsg);
    return true;
  };
  couponGetRes=(responseJson:any)=>{
if(responseJson.data){
  this.setState({offersData:responseJson.data})
}
  }
  couponPostRes=(responseJson:any)=>{
    if(responseJson){
      this.setState({offersObject:responseJson})
      this.setState({ couponModal: false });
    }
  }
  DeletecouponPostRes=(responseJson:any)=>{
    if(responseJson.message){
      this.setState({errorMsg:responseJson.message,offersObject:{}, toastOpen:true})
    }
  }
  functionDiscount=(item:any)=>{
    if (item && item.original_price && item.discounted_price) {
      return item.original_price - item.discounted_price;
    }
    return 0;
  }
  // Customizable Area End

  // Customizable Area Start

  // Customizable Area End
}
