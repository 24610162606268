export const circleIcon = require("../assets/circle.png");
export const crossIcon = require("../assets/cross.png");
export const bgImage = require("../assets/background1.png");
export const oceanbackground = require("../assets/background.png");
export const airoplane = require("../assets/image_airplanemode_active_24px.png");
export const umbrella = require("../assets/image_Beach Umbrella.png");
export const errorIcon = require("../assets/error.png")
export const noOffers = require("../assets/image_Avoid Flights.png")
export const noBlog = require("../assets/noBlog.png")
export const couponIcon = require("../assets/image_Offers.png");
export const closeIcon = require("../assets/image_clear_24px.png");