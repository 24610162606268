import React from "react";
// Customizable Area Start
import { Box, Button, Checkbox, Dialog, DialogContent, Snackbar, Typography } from "@material-ui/core";
import CouponDiscountsController, { Props } from "./CouponDiscountsController";
import CloseIcon from '@material-ui/icons/Close';
import { couponIcon, closeIcon } from "./assets";
import "../assets/style.css"
import { CheckCircleOutline } from "@material-ui/icons";
// Customizable Area End

// Customizable Area Start

// Customizable Area End

export default class CouponDiscounts extends CouponDiscountsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
   
    // Customizable Area End
    return (
      // Customizable Area Start
      <div style={webStyle.container}>
         <Snackbar open={this.state.toastOpen}  anchorOrigin={{ vertical: 'top', horizontal: 'right'}} onClose={()=> this.setState({toastOpen:false})} autoHideDuration={3000}>
                    <Typography style={{padding:'8px',background:"rgb(23, 100, 160)",color:'white',borderRadius:'4px',display:'flex',alignItems:'center',gap:'10px'}}>
                        <CheckCircleOutline /> {this.state.errorMsg}
                    </Typography>
                </Snackbar>
      <Box>
        <Box
          data-test-id="myCoupn"
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "20px 7px 0 7px",
          }}
        >
          <Typography style={webStyle.header}>Discount</Typography>
          {
             this.state.offersObject?.apply_offer?.data?.id?<>
                       <Typography style={webStyle.applyButton}><span data-test-id="removeFunct" style={{display:"flex",alignItems:"center"}} onClick={()=>
                        this.removeFunct(this.state.offersObject?.apply_offer?.data?.id)
                       }>APPLIED 
                       &nbsp;&nbsp; <img src={closeIcon} /></span></Typography>

             </>:<>
                       <Typography style={webStyle.applyButton}><span data-test-id="couponModal" onClick={this.applyFunct}>APPLY</span></Typography>
             </>
          }
        </Box>
        <Box data-test-id="upcoming-container" style={webStyle.discountRow}>
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Checkbox color="primary" />
            <Typography style={webStyle.labelText}>Credit Score</Typography>
          </Box>
          <Typography style={webStyle.amountText}>INR 0</Typography>
        </Box>
      </Box>
      <Box>
        <Typography style={webStyle.header}>Payment Details</Typography>
        <Box style={webStyle.paymentRow}>
          <Typography style={webStyle.labelText}>
            Base Fare
            <br />
          </Typography>
          <Typography style={{...webStyle.amountText, color:"grey"}}>INR {this.props.baseFare}</Typography>
        </Box>
        <Box style={webStyle.paymentRow}>
          <Typography style={webStyle.labelText}>Taxes and Surcharges</Typography>
          <Typography style={webStyle.amountText}>INR {this.props.taxesSurchargees}</Typography>
        </Box>
        <Box style={webStyle.paymentRow}>
          <Typography style={webStyle.labelText}>Discount</Typography>
          <Typography style={webStyle.amountText}>INR {this.functionDiscount(this.state.offersObject)}</Typography>
        </Box>
        <Box style={webStyle.paymentRow}>
          <Typography style={webStyle.labelText}>Credit Score</Typography>
          <Typography style={webStyle.amountText}>INR 0</Typography>
        </Box>
        <Box data-test-id="total-amt" style={webStyle.totalAmountRow}>
          <Typography style={webStyle.highlightedText}>
            Total Amount
          </Typography>
          <Typography style={webStyle.highlightedText}>INR {this.state.offersObject?.discounted_price?this.state.offersObject?.discounted_price:this.props.totalAmount}</Typography>
        </Box>
      </Box>
      <Dialog
        data-testid="ConfirmCancelModal"
        maxWidth={false}
        open={this.state.couponModal}
        PaperProps={{
          style: {
            width: "50%",
            maxWidth: "none",
          },
          className: "modalFareRules",
        }}
      >
        <DialogContent style={{ padding: "0" }}>
          <div>
            <Box style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
              <Typography
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "15px",
                }}
              >
                <span
                className="mediaq-text"
                  style={{
                    display: "block",
                    width: "100%",
                    fontSize: "24px",
                    fontWeight: 700,
                  }}
                >
                  Select Discount Offer
                </span>
                <span
                  data-test-id="resetReviewModal"
                  onClick={this.closeModal}
                  style={{
                    display: "block",
                    width: "100%",
                    textAlign: "right",
                    cursor: "pointer",
                  }}
                >
                  <CloseIcon />
                </span>
              </Typography>
            </Box>
            <Box
              className="yscrollwindow"
              style={{ maxHeight: "300px", overflowY: "auto" }}
            >
              {this.state.offersData.map((offer:any) => (
                <Box
                className="mediaq-pd"
                  key={offer}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "15px 20px",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <Typography className="media-code-text" style={{ fontWeight: 600,display: "flex", alignItems: "center",fontSize:"18px" }}> <img src={couponIcon} />&nbsp;&nbsp; {offer.attributes.offer_code}</Typography>
                  {this.state.appliedCoupon === offer.attributes.id ? (
                    <Button
                     className="media-btn"
                      variant="contained"
                      color="primary"
                      style={{ textTransform: "none", background:"rgb(23, 100, 160)", width:"170px", padding:"7px 30px",borderRadius:"5px" }}
                    >
                      Applied
                    </Button>
                  ) : (
                    <Button
                    className="media-btn"
                      variant="outlined"
                      data-test-id="handleApplyCoupon"
                      color="primary"
                      onClick={() => this.handleApplyCoupon(offer)}
                      style={{ textTransform: "none",padding:"7px 30px",width:"170px", fontWeight:700, color:"#000", border:"1px solid #CBD5E1", borderRadius:"5px" }}
                    >
                      Apply
                    </Button>
                  )}
                </Box>
              ))}
            </Box>
            <Box
              style={{
                padding: "20px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                data-test-id="handleSubmitOk"
                variant="contained"
                color="primary"
                style={{background:"rgb(23, 100, 160)"}}
                onClick={this.handleSubmitOk} 
              >
                Ok
              </Button>
            </Box>
          </div>
        </DialogContent>
      </Dialog>
    </div>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {
  container: {
    width: "100%",
    padding: "0px",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    borderRadius: "15px",
    background: "white",
    marginTop: "15px",
  },
  header: {
    fontSize: "24px",
    fontWeight: 700,
    marginBottom: "14px",
    padding: "0 17px",
  },
  discountRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 20px 0 20px",
  },
  paymentRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 17px",
  },
  totalAmountRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 17px",
  },
  labelText: {
    fontSize: "14px",
    fontWeight: 600,
    color: "#000",
  },
  amountText: {
    fontSize: "14px",
    fontWeight: 600,
    color: "#000",
  },
  highlightedText: {
    fontSize: "16px",
    fontWeight: 700,
    color: "#000",
  },
  applyButton: {
    fontSize: "16px",
    fontWeight: 600,
    color: "rgb(23, 100, 160)",
    cursor: "pointer",
  },
};
// Customizable Area End
